import type { ComputedRef } from "vue";
import { computed } from "vue";
import type {
  FinancialProduct,
  ILfColumn,
  IOfferColumns
} from "@/models/common";
import { useDeals } from "@/hooks/deals";
import {
  formatDateCustom,
  formatMoney,
  formatPercentage
} from "@/helpers/formatting";
import { useAuth } from "./auth";
import { useI18n } from "vue-i18n";
import type { IOffer } from "@/models/funders";
import OfferShared from "@/components/ui/OfferShared.vue";
import { useStore } from "vuex";

interface PropsValues {
  isFundingDetails: boolean;
  productType: FinancialProduct;
  showStartAndEndDates: boolean;
  showFundingData: boolean;
  showSelectingOffers: boolean;
}
type Props = Readonly<PropsValues>;

interface UseOffersColumnsReturnTypes {
  columns: ComputedRef<IOfferColumns>;
  moneyToTwoDecimal: (money: number | string) => string;
  addEqFinancingStartAndEndDates: (columns: ILfColumn[]) => void;
  addAdvancePaymentsAndFee: (columns: ILfColumn[]) => void;
  addComissionRate: (columns: ILfColumn[]) => void;
  addGeneratedAt: (columns: ILfColumn[]) => void;
  isFunder: boolean;
  canEditDealProgress: ComputedRef<boolean>;
}

export const useOffersColumns = (props: Props): UseOffersColumnsReturnTypes => {
  const { isActiveDealSelfFunded, canEditDealProgress } = useDeals();
  const { isFunder, isClient } = useAuth();
  const { t } = useI18n();
  const { getters } = useStore();

  const showFundingAmount = computed(
    () =>
      props.isFundingDetails &&
      ["term loan", "equipment financing", "receivables purchase"].includes(
        (props.productType || "").toLowerCase()
      )
  );

  const wfbTemplateColumn = computed(() => ({
    key: "workflowTemplateName",
    label: t("WORKFLOW.TEMPLATES", 1),
    formatModel: true,
    placeholder: "-",
    formatter: (model: IOffer) => {
      // 4 = Lendflow Submission Method
      return model.placement?.method === 4
        ? model.placement.workflow_template?.name || "-"
        : "-";
    }
  }));

  const valueToTwoDecimals = (num: string): string =>
    parseFloat(num).toFixed(2);

  const moneyToTwoDecimal = (money: number | string): string =>
    formatMoney(money, 2);

  const percentToTwoDecimal = (value: number): string | null =>
    formatPercentage(value, 2);

  const sharedColumns = props.showSelectingOffers
    ? [
        {
          label: t("OFFERS.COLUMNS.VISIBILITY_ON_BP"),
          key: "is_shared",
          component: OfferShared
        }
      ]
    : [];

  const baseRateColumn = {
    label: t("OFFERS.COLUMNS.BASE_RATE"),
    key: "base_rate",
    formatter: (baseRate: number) => getters["options/baseRate"][baseRate]
  };

  const interestChargedTimeframeColumn = {
    label: t("OFFERS.COLUMNS.INTEREST_CHARGED"),
    key: "interest_charged",
    formatter: (interestCharged: number) =>
      getters["options/interestChargedTimeframe"][interestCharged]
  };

  const termLengthTimeframeColumn = {
    label: t("OFFERS.COLUMNS.TERM_LENGTH_TIMEFRAME"),
    key: "term_length_timeframe",
    formatter: (termLengthTimeframe: number) =>
      getters["options/termLengthTimeframe"][termLengthTimeframe]
  };

  const mutualColumns = computed(() => [
    ...sharedColumns,
    { label: t("OFFERS.COLUMNS.SENT_BY"), key: "sent_by.first_name" },
    { label: "", key: "contacted_via", component: "offer-contacted-via" },
    {
      label: isActiveDealSelfFunded.value
        ? t("OFFERS.COLUMNS.MERCHANT_STATUS")
        : t("COMMON.STATUS"),
      key: "status",
      component:
        (!isFunder || isClient) && canEditDealProgress.value
          ? "offer-pill-dropdown"
          : "offer-status-pill"
    },
    wfbTemplateColumn.value,
    {
      label: showFundingAmount.value
        ? t("OFFERS.COLUMNS.FUNDING_AMOUNT")
        : t("OFFERS.COLUMNS.OFFER_AMOUNT"),
      key: showFundingAmount.value
        ? "funding_data.funding_amount"
        : "offer_amount",
      formatter: moneyToTwoDecimal
    },
    {
      label: t("OFFERS.COLUMNS.PAYBACK_AMOUNT"),
      key: "payback_amount",
      formatter: moneyToTwoDecimal
    },
    {
      label: t("OFFERS.COLUMNS.PAYMENT_AMOUNT"),
      key: "payment_amount",
      formatter: moneyToTwoDecimal
    },
    { label: t("OFFERS.COLUMNS.NUM_OF_PAYMENTS"), key: "number_of_payments" },
    {
      label: t("OFFERS.COLUMNS.PAYMENT_FREQUENCY"),
      key: "payment_frequency",
      options: "offers.payment_periods"
    },
    {
      label: t("OFFERS.COLUMNS.TERM"),
      key: "termLength",
      placeholder: "-",
      formatter: valueToTwoDecimals,
      nowrap: true
    },
    termLengthTimeframeColumn
  ]);

  const columns = computed<IOfferColumns>(() => ({
    ARLOC: [
      ...sharedColumns,
      { label: t("OFFERS.COLUMNS.SENT_BY"), key: "sent_by.first_name" },
      { label: "", key: "contacted_via", component: "offer-contacted-via" },
      {
        label: isActiveDealSelfFunded.value
          ? t("OFFERS.COLUMNS.MERCHANT_STATUS")
          : t("COMMON.STATUS"),
        key: "status",
        component:
          (!isFunder || isClient) && canEditDealProgress.value
            ? "offer-pill-dropdown"
            : "offer-status-pill"
      },
      wfbTemplateColumn.value,
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.FACILITY_AMOUNT")
          : t("OFFERS.COLUMNS.MAX", {
              suffix: t("OFFERS.COLUMNS.FACILITY_AMOUNT")
            }),
        key: "max_facility_amount",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.ADVANCE_RATE"),
        key: "advance_rate",
        formatter: percentToTwoDecimal
      },
      baseRateColumn,
      interestChargedTimeframeColumn,
      {
        label: t("OFFERS.COLUMNS.DISCOUNT_RATE_PER_DAY", { value: 30 }),
        key: "30_day_discount_rate",
        formatter: percentToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.DISCOUNT_RATE_PER_DAY", { value: 30 }),
        key: "10_day_discount_rate_after_initial_30_days",
        formatter: percentToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.EXPENSE_DEPOSIT"),
        key: "expense_deposit",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.ORIGINATION_FEE"),
        key: "origination_fee",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.RENEWAL_FEE"),
        key: "renewal",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.TERM"),
        key: "termLength",
        placeholder: "-",
        nowrap: true
      },
      termLengthTimeframeColumn
    ],
    "Line of credit": [
      ...sharedColumns,
      { label: t("OFFERS.COLUMNS.SENT_BY"), key: "sent_by.first_name" },
      { label: "", key: "contacted_via", component: "offer-contacted-via" },
      {
        label: isActiveDealSelfFunded.value
          ? t("OFFERS.COLUMNS.MERCHANT_STATUS")
          : t("COMMON.STATUS"),
        key: "status",
        component:
          (!isFunder || isClient) && canEditDealProgress.value
            ? "offer-pill-dropdown"
            : "offer-status-pill"
      },
      wfbTemplateColumn.value,
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.LOC")
          : t("OFFERS.COLUMNS.MAX", {
              suffix: t("OFFERS.COLUMNS.LOC")
            }),
        key: "max_line_of_credit",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.TERM"),
        key: "termLength",
        placeholder: "-",
        nowrap: true
      },
      termLengthTimeframeColumn,
      {
        label: t("OFFERS.COLUMNS.PAYMENT_FREQUENCY"),
        key: "payment_frequency",
        options: "offers.payment_periods"
      },
      {
        label: t("OFFERS.COLUMNS.INTEREST"),
        key: "monthly_interest",
        formatter: percentToTwoDecimal
      },
      baseRateColumn,
      interestChargedTimeframeColumn,
      {
        label: t("OFFERS.COLUMNS.FACTOR_RATE"),
        key: "factor_rate"
      },
      {
        label: t("OFFERS.COLUMNS.FIRST_DRAW_COMMISSION"),
        key: "first_draw_commission",
        formatter: percentToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.SUBSEQUENT_DRAW_COMMISSION"),
        key: "subsequent_draw_commission"
      }
    ],
    "Receivables Purchase": [
      ...mutualColumns.value,
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.SELL_FACTOR_RATE")
          : t("OFFERS.COLUMNS.MAX", {
              suffix: t("OFFERS.COLUMNS.SELL_FACTOR_RATE")
            }),
        key: "max_sell_factor_rate"
      },
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.BUY_FACTOR_RATE")
          : t("OFFERS.COLUMNS.MIN", {
              suffix: t("OFFERS.COLUMNS.BUY_FACTOR_RATE")
            }),
        key: "min_buy_factor_rate"
      },
      interestChargedTimeframeColumn
    ],
    "Term loan": [
      ...mutualColumns.value,
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.SELL_INTEREST_RATE")
          : t("OFFERS.COLUMNS.MAX", {
              suffix: t("OFFERS.COLUMNS.SELL_INTEREST_RATE")
            }),
        key: "max_sell_interest_rate",
        formatter: valueToTwoDecimals
      },
      baseRateColumn,
      interestChargedTimeframeColumn,
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.BUY_INTEREST_RATE")
          : t("OFFERS.COLUMNS.MIN", {
              suffix: t("OFFERS.COLUMNS.BUY_INTEREST_RATE")
            }),
        key: "min_buy_interest_rate",
        formatter: valueToTwoDecimals
      },
      {
        label: t("OFFERS.COLUMNS.INTEREST_AMOUNT"),
        key: "interest_amount",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.APR"),
        key: "apr",
        formatter: percentToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.INTEREST_AND_FEE"),
        key: "fee",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.TIME_TO_FUNDING"),
        key: "time_to_funding"
      },
      {
        label: t("OFFERS.COLUMNS.TIME_TO_FUNDING_TIMEFRAME"),
        key: "time_to_funding_timeframe",
        formatter: (timeToFundingTimeframe: number) =>
          getters["options/timeToFindingTimeframe"][timeToFundingTimeframe]
      }
    ],
    "Equipment Financing": [
      ...mutualColumns.value,
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.SELL_INTEREST_RATE")
          : t("OFFERS.COLUMNS.MAX", {
              suffix: t("OFFERS.COLUMNS.SELL_INTEREST_RATE")
            }),
        key: "max_sell_interest_rate",
        formatter: valueToTwoDecimals
      },
      {
        label: props.showFundingData
          ? t("OFFERS.COLUMNS.BUY_INTEREST_RATE")
          : t("OFFERS.COLUMNS.MIN", {
              suffix: t("OFFERS.COLUMNS.BUY_INTEREST_RATE")
            }),
        key: "min_buy_interest_rate",
        formatter: valueToTwoDecimals
      },
      interestChargedTimeframeColumn
    ],
    ERC: [
      ...sharedColumns,
      { label: t("OFFERS.COLUMNS.SENT_BY"), key: "sent_by.first_name" },
      { label: "", key: "contacted_via", component: "offer-contacted-via" },
      {
        label: isActiveDealSelfFunded.value
          ? t("OFFERS.COLUMNS.MERCHANT_STATUS")
          : t("COMMON.STATUS"),
        key: "status",
        component:
          (!isFunder || isClient) && canEditDealProgress.value
            ? "offer-pill-dropdown"
            : "offer-status-pill"
      },
      wfbTemplateColumn.value,
      {
        label: t("OFFERS.COLUMNS.CREDIT_AMOUNT"),
        key: "erc_credit_amount",
        formatter: moneyToTwoDecimal
      },
      {
        label: t("OFFERS.COLUMNS.FILING_DATE"),
        key: "erc_filing_date",
        formatter: formatDateCustom
      },
      {
        label: t("OFFERS.COLUMNS.TOTAL_COMMISSION"),
        key: "erc_total_commission",
        formatter: moneyToTwoDecimal
      }
    ]
  }));

  const addEqFinancingStartAndEndDates = (columns: ILfColumn[]) => {
    if (props.showStartAndEndDates) {
      // keys for start and end dates are different for Equipment Financing
      const startDateColIndex = columns.findIndex((c) =>
        ["start_date", "funding_start_date"].includes(c.key)
      );
      const endDateColIndex = columns.findIndex((c) =>
        ["end_date", "funding_end_date"].includes(c.key)
      );

      if (startDateColIndex < 0) {
        columns.push({
          label: t("COMMON.START_DATE"),
          key: "start_date",
          formatter: formatDateCustom,
          placeholder: "-"
        });
      }

      if (endDateColIndex < 0) {
        columns.push({
          label: t("COMMON.END_DATE"),
          key: "end_date",
          formatter: formatDateCustom,
          placeholder: "-"
        });
      }

      const newStartDateColIndex = columns.findIndex(
        (c) => c.key === "start_date"
      );
      const newEndDateColIndex = columns.findIndex((c) => c.key === "end_date");

      if (
        props.productType === "Equipment Financing" &&
        ![newStartDateColIndex, newEndDateColIndex].includes(-1)
      ) {
        columns[newStartDateColIndex].key = "funding_start_date";
        columns[newEndDateColIndex].key = "funding_end_date";
      }
    }
  };

  const addAdvancePaymentsAndFee = (columns: ILfColumn[]) => {
    const numOfPaymentsId = columns.findIndex(
      (col) => col.key === "number_of_payments"
    );
    if (numOfPaymentsId !== -1) {
      columns.splice(numOfPaymentsId + 1, 0, {
        label: t("OFFERS.COLUMNS.ADVANCE_PAYMENTS"),
        key: "advance_payments",
        formatter: moneyToTwoDecimal
      });
      columns.splice(numOfPaymentsId + 2, 0, {
        label: t("OFFERS.COLUMNS.FEE"),
        key: "fee",
        formatter: moneyToTwoDecimal
      });
    }
  };

  const addComissionRate = (columns: ILfColumn[]) => {
    columns.push({
      label: props.showFundingData
        ? t("OFFERS.COLUMNS.COMMISSION")
        : t("OFFERS.COLUMNS.MAX", { suffix: t("OFFERS.COLUMNS.COMMISSION") }),
      key: props.showFundingData ? "funding_commission" : "max_commission_rate"
    });
  };

  const addGeneratedAt = (columns: ILfColumn[]) => {
    const statusColumnIndex = columns.findIndex((col) => col.key === "status");
    if (statusColumnIndex !== -1) {
      columns.splice(statusColumnIndex + 1, 0, {
        label: t("OFFERS.COLUMNS.OFFER_DATE"),
        key: "offer_generated_date",
        formatter: (value: string | Date) =>
          formatDateCustom(String(value), "MMM dd, yyyy", true),
        placeholder: "-"
      });
    }
  };

  return {
    columns,
    moneyToTwoDecimal,
    addEqFinancingStartAndEndDates,
    addAdvancePaymentsAndFee,
    addComissionRate,
    isFunder,
    canEditDealProgress,
    addGeneratedAt
  };
};
