<template>
  <lf-h4 v-if="productType && showProductTypeName" class="mt-4 mb-3">
    {{ productType }}
  </lf-h4>
  <lf-table
    v-if="offers?.length"
    ref="tableRef"
    :class="{
      'freeze-border-first freeze-first': freezeFirstColumn
    }"
    :select-all="selectAll"
    :select-one="selectOne"
    :select-one-key="selectOneKey"
    :columns="productTypeColumns as IDealsColumn[]"
    :data="offersWithTerm"
    :row-is-clickable="rowIsClickable"
    :select-details-provider="selectDetailsProvider"
    :select-title="selectTitle"
    :role="role"
    :custom-id="productType"
    hide-scrollbar-x
    header-no-wrap
    data-cy="offers-table"
    @selectedRows="handleSelectedRows($event, productType)"
    @selectedRow="handleSelectedRow"
    @row-clicked="$emit('rowClicked', $event)"
  />
  <div v-else class="flex items-center justify-center">
    <span>
      {{ $t("COMMON.OFFERS_EMPTY") }}
    </span>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import {
  formatTerms,
  formatDateCustom,
  truncateUUID
} from "@/helpers/formatting";

import type { IOffer } from "@/models/funders";
import type {
  ILfColumn,
  FinancialProduct,
  SelectDetailsProvider
} from "@/models/common";
import { useOffersColumns } from "@/hooks/offers";
import { EQUIPMENT_FINANCING, RECEIVABLES_PURCHASE } from "@/helpers/constants";
import { useAuth } from "@/hooks/auth";
import { useActiveStage } from "@/hooks/workflow";
import LfTable from "@/components/ui/table/LfTable.vue";
import type { IDealsColumn } from "@/models/applications";
import cloneDeep from "lodash/cloneDeep";

const emit = defineEmits(["selectedRow", "selectedRows", "rowClicked"]);

const props = defineProps({
  selectAll: {
    type: Boolean,
    default: false
  },
  selectOne: {
    type: Boolean,
    default: false
  },
  selectOneKey: {
    type: String,
    default: ""
  },
  offers: {
    type: Array as PropType<Partial<IOffer & { mockRow?: boolean }>[]>,
    required: true
  },
  showId: {
    type: Boolean,
    default: false
  },
  productType: {
    type: String as PropType<FinancialProduct>,
    default: ""
  },
  rowIsClickable: {
    type: Boolean,
    default: false
  },
  showStartAndEndDates: {
    type: Boolean,
    default: false
  },
  showFundedDate: {
    type: Boolean,
    default: false
  },
  showExpiresAt: {
    type: Boolean,
    default: false
  },
  showGeneratedAt: {
    type: Boolean,
    default: false
  },
  freezeFirstColumn: {
    type: Boolean,
    default: false
  },
  showProductTypeName: {
    type: Boolean,
    default: true
  },
  selectDetailsProvider: {
    type: [Function, Object] as PropType<SelectDetailsProvider>,
    default: null
  },
  selectTitle: {
    type: String,
    default: ""
  },
  showFundingData: {
    type: Boolean,
    default: false
  },
  showStatusPill: {
    type: Boolean,
    default: false
  },
  isFundedPhase: {
    type: Boolean,
    default: false
  },
  isFundingDetails: {
    type: Boolean,
    default: false
  },
  role: {
    type: String,
    default: ""
  },
  disableEdit: {
    type: Boolean,
    default: false
  },
  // This prop appears as not used, but is actually used by the "useOffersColumns" hook in the setup
  showSelectingOffers: {
    type: Boolean,
    default: false
  }
});

const {
  columns,
  addEqFinancingStartAndEndDates,
  addAdvancePaymentsAndFee,
  addComissionRate,
  canEditDealProgress,
  addGeneratedAt,
  isFunder
} = useOffersColumns(props);
const { isClosingStage } = useActiveStage();
const { isClient } = useAuth();
const { t } = useI18n();
const tableRef = ref<InstanceType<typeof LfTable> | null>(null);

const handleSelectedRow = (id: string | number, checked: boolean) =>
  emit("selectedRow", id, checked);

const handleSelectedRows = (ids: (number | string)[], productType: string) =>
  emit("selectedRows", ids, productType);

const offersWithTerm = computed(() => {
  const offers = props.offers.map((offer) =>
    !offer.mockRow
      ? {
          ...offer,
          termLength: formatTerms(offer),
          start_date: offer.funding_data?.start_date || "",
          end_date: offer.funding_data?.end_date || ""
        }
      : offer
  );

  if (!props.showFundingData) {
    return offers;
  }

  return offers.map((offer) => {
    const hasAtLeastOneTruthyField = Object.values(
      offer.funding_data || []
    ).some(Boolean);

    if (offer.status !== 1 || !hasAtLeastOneTruthyField) {
      return offer;
    }

    const withFundingData = {
      ...offer,
      ...offer.funding_data
    };

    return {
      ...withFundingData,
      termLength: formatTerms(withFundingData as IOffer)
    };
  });
});

const computedColumns = computed(() => {
  const columnsInstance = { ...columns.value };
  let colVal: ILfColumn[];

  // this condition refers to the case when the user is viewing the funding details table
  if (props.isFundingDetails) {
    for (colVal of Object.values(columnsInstance)) {
      const statusIndex = colVal.findIndex((col) => col.key === "status");
      colVal[statusIndex].component = "offer-status-pill";
      colVal[statusIndex].props = {
        showFundedInsteadOfAccepted: true
      };

      if (props.productType === EQUIPMENT_FINANCING) {
        addAdvancePaymentsAndFee(colVal);
      }
      if (props.productType === RECEIVABLES_PURCHASE) {
        addComissionRate(colVal);
      }
      if (props.showGeneratedAt) {
        addGeneratedAt(colVal);
      }
      addEqFinancingStartAndEndDates(colVal);
    }
    return columnsInstance;
  }
  //  and this condition refers to the regular displaying of the offers table
  for (colVal of Object.values(columnsInstance)) {
    if (props.productType === RECEIVABLES_PURCHASE) {
      addComissionRate(colVal);
    }

    if (props.showStartAndEndDates) {
      colVal.push({
        label: t("COMMON.START_DATE"),
        key: "start_date",
        formatter: formatDateCustom,
        placeholder: "-"
      });
      colVal.push({
        label: t("COMMON.END_DATE"),
        key: "end_date",
        formatter: formatDateCustom,
        placeholder: "-"
      });
    }

    colVal.push({
      label: t("OFFERS.COLUMNS.ONBOARDING"),
      key: "funding_advisor_full_name",
      placeholder: "-"
    });

    colVal.push({
      label: t("OFFERS.COLUMNS.SUBMISSION_DATE"),
      key: "placement.created_at",
      formatter: formatDateCustom,
      placeholder: "-"
    });

    if (props.showExpiresAt) {
      colVal.push({
        label: t("OFFERS.COLUMNS.OFFER_EXPIRATION_DATE"),
        key: "offer_expiration_date",
        formatter: formatDateCustom,
        placeholder: "-"
      });
    }

    if (props.isFundedPhase) {
      if (props.productType === EQUIPMENT_FINANCING) {
        addAdvancePaymentsAndFee(colVal);
      }
    }

    if (props.showStatusPill) {
      const index = colVal.findIndex((col) => col?.key === "status");
      colVal[index].component = "offer-status-pill";
      colVal[index].props = {
        showFundedInsteadOfAccepted: props.isFundingDetails
      };
    }

    if (props.showGeneratedAt) {
      addGeneratedAt(colVal);
    }
  }

  return columnsInstance;
});

const someOfferHasDeclinedReason = computed(() =>
  props.offers.some((offer) => Boolean(offer.declined_reason))
);

const productTypeColumns = computed<ILfColumn[] | IDealsColumn[]>(() => {
  const calculatedColumns: ILfColumn[] | IDealsColumn[] | undefined = cloneDeep(
    computedColumns.value[
      props.productType as keyof typeof computedColumns.value
    ]
  );

  if (!calculatedColumns?.length) {
    return [];
  }

  if (props.showFundedDate) {
    calculatedColumns.push({
      label: t("OFFERS.COLUMNS.FUNDED_DATE"),
      key: "funded_date",
      formatter: formatDateCustom,
      placeholder: "-"
    });
  }

  if (props.showId && !calculatedColumns.some((col) => col?.key === "id")) {
    calculatedColumns.splice(0, 0, {
      label: t("OFFERS.COLUMNS.ID"),
      key: "id",
      formatter: truncateUUID
    });
  }

  if (someOfferHasDeclinedReason.value) {
    calculatedColumns.push({
      label: t("OFFERS.COLUMNS.DECLINE_REASON"),
      key: "declined_reason"
    });
  }

  if (
    (!isFunder || isClient) &&
    canEditDealProgress.value &&
    !props.disableEdit
  ) {
    // insert after status column
    calculatedColumns.push({
      label: "",
      key: "action",
      component: "offer-dropdown-menu",
      props: {
        showTitle: false,
        isFundingDetails: props.isFundingDetails
      }
    });
  }

  addEqFinancingStartAndEndDates(calculatedColumns);

  if (isClosingStage.value) {
    const closingStageColumns = calculatedColumns.filter(
      (column) => !["sent_by.first_name", "contacted_via"].includes(column.key)
    );

    closingStageColumns.unshift({
      label: "",
      key: "contacted_via",
      component: "offer-contacted-via"
    });

    closingStageColumns.unshift({
      label: t("DEALS.TABLE_HEADERS.SENT_BY"),
      key: "sent_by.first_name"
    });

    closingStageColumns.unshift({
      label: t("DEALS.TABLE_HEADERS.FUNDER"),
      key: "funder_full_name",
      placeholder: "-"
    });

    return closingStageColumns;
  }

  return calculatedColumns;
});

defineExpose({ tableRef });
</script>
